<template>
  <div class="composite">
    <div class="goods">
      <div class="icon">
        <span class="icon-container">
          <img
            class="icon-container-img"
            :src="data.mergeTokenConfigDto.coverUrl"
          />
        </span>
      </div>
      <div class="infos">
        <div class="name">{{data.mergeTokenConfigDto.displayName}}</div>
        <div class="count">
          <div class="count-l">
            <img
              src="../assets/icon/composite/limit.png"
              alt=""
            >
            <p>每人</p>
          </div>
          <div class="count-r">
            <p>{{data.mergeTokenConfigDto.limitationFlag == 1 ? `${data.mergeTokenConfigDto.limitationNum}份` : '不限'}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="num">
      <p>合成数量</p>
      <van-stepper
        v-model="value"
        @change="valueChange"
        @plus="valuePlus"
        @minus="valueMinus"
        min="1"
        button-size="22"
        disable-input
        :disable-plus="materialMaxLength == 0 || materialMaxLength == value"
      />
    </div>
    <div class="material-title">
      合成材料
    </div>
    <!-- 合成材料 -->
    <div
      v-for="(item,index) in materialList"
      :key="index"
      class="row-between material-item-container"
    >
      <div class="row-start">
        <img
          class="material-item-container-left-image"
          :src="item.image"
        />
        <div>
          <p class="material-item-container-left-name">{{ item.name }}</p>
          <p class="material-item-container-left-amount">需要{{ item.amount * value }} 份</p>
        </div>
      </div>
      <div
        @click="chooseMaterial(item.skuNo)"
        class="row-end"
      >
        <p class="material-item-container-right-tip">更换</p>
        <img
          class="material-item-container-right-icon"
          src="../assets/icon/next.png"
        />
      </div>
    </div>
    <!-- <div
      v-if="value != 0"
      class="material"
    >
      <div class="nav">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          :style="`width:${value * 55}px`"
          @select="handleSelect"
        >
          <el-menu-item
            :index="`${j + 1}`"
            v-for="(i,j) in value"
            :key="`nav-${j}`"
          >
            第{{j + 1}}组
            <img
              v-if="activeIndex == j+1"
              src="../assets/icon/list-select.png"
            />
          </el-menu-item>
        </el-menu>
      </div>
      <div class="groups">
        <div
          v-for="(i, j) in activeMaterialList"
          :key="j"
          class="group"
        >
          <div class="icon">
            <span class="icon-container">
              <img
                class="icon-container-img"
                :src="i.image"
              />
            </span>
            <div
              class="icon-modal"
              @click="chooseMaterial(i, j)"
            >
              <div class="icon-modal-container">
                <p>更换</p>
              </div>
            </div>
          </div>
          <div class="name">{{i.name}}</div>
          <div class="id">{{i.uniqueCode}}</div>
        </div>
      </div>
    </div> -->
    <div class="fee">
      <p>上链服务费</p>
      <p>￥ {{getFee}}</p>
    </div>
    <p class="tips">注：合成为不可逆操作，请谨慎操作，避免产生不必要损失。</p>
    <div
      style="z-index:1999;"
      class="btns"
    >
      <div
        v-if="isShowComposite"
        class="completed-pay"
        @click="composite"
      >立即合成</div>
    </div>
    <!-- <div class="btns">
      <div
        class="completed-pay"
        @click="cancel"
      >取消</div>
    </div> -->
    <el-dialog
      title="尚未设置资产密码"
      :visible.sync="donationVisible"
      @confirm="donationVisible = false"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="donationVisible = false">取 消</el-button>
        <div
          @click="$router.push('/resetPwd')"
          class="confirm-button row-center"
        >去设置</div>
      </span>
    </el-dialog>
    <van-popup
      closeable
      v-model="show"
      position="bottom"
      :style="`${payStep == 2 ? '':'height: 411px;'}background: rgba(21, 21, 21, 0.85);`"
      :close-on-click-overlay="false"
    >
      <div v-if="payStep == 1">
        <p class="donation-popup-title">安全验证</p>
        <!-- 密码输入框 -->
        <p class="donation-popup-tip">验证资产密码</p>
        <van-password-input
          :value="pwdValue"
          :gutter="8"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="pwdValue"
          :show="showKeyboard"
          @blur="show = false"
        />
      </div>
      <div v-if="payStep == 2">
        <p class="donation-popup-title">支付上链服务费</p>
        <p class="donation-popup-donationPay">¥ {{ getFee }}</p>
        <div
          v-if="(buyType == '1' || buyType == '3') && data.mergeTokenConfigDto.serviceFeeFlag == 1"
          :style="isWechat() || buyType == '1' ? 'margin-top:120px;': ''"
          @click="selectPay('wx')"
          class="order-detail-wx row-between"
        >
          <div class="row-center">
            <img
              class="wx-icon"
              src="../assets/icon/donation/wx.png"
            />
            <p class="wx-tip">微信支付</p>
          </div>
          <img
            :style="wxPay ? 'display: block;': 'display: none;'"
            id="wxSelect"
            class="buy-wx-select"
            src="../assets/icon/donation/select.png"
          />
          <img
            :style="wxPay ? 'display: none;': 'display: block;'"
            id="wxCancer"
            class="buy-wx-cancer"
            src="../assets/icon/donation/cancer.png"
          />
        </div>
        <div
          v-if="!isWechat() && (buyType == '2' || buyType == '3') && data.mergeTokenConfigDto.serviceFeeFlag == 1"
          :style="buyType == '2' ? 'margin-top:120px;': ''"
          @click="selectPay('zfb')"
          class="order-detail-zfb row-between"
        >
          <div class="row-center">
            <img
              class="zfb-icon"
              src="../assets/icon/donation/zfb.png"
            />
            <p class="zfb-tip">支付宝支付</p>
          </div>
          <img
            id="zfbSelect"
            :style="this.buyType == '2' ? 'display: block;': 'display: none;'"
            class="buy-zfb-select"
            src="../assets/icon/donation/select.png"
          />
          <img
            id="zfbCancer"
            :style="this.buyType == '2' ? 'display: none;': 'display: block;'"
            class="buy-zfb-cancer"
            src="../assets/icon/donation/cancer.png"
          />
        </div>
        <van-button
          :class="buyType == '0' || (this.isWechat() && buyType == '2') ? 'no-access-donation pay-btn': 'pay-btn'"
          round
          block
          type="info"
          @click="getAppId"
        >立 即 支 付</van-button>
      </div>
      <!-- <div
        class="close-icon"
        @click="closePopup"
      >
        <img
          src="../assets/icon/close-icon.png"
          alt=""
        >
      </div> -->
    </van-popup>
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <van-popup
      v-model="showMaterial"
      class="materailPop"
      closeable
      position="bottom"
      style="height: 100%;background: rgba(21, 21, 21, 1);"
    >
      <div class="materials1">
        <div
          class="material1"
          v-for="(i, j) in selectMaterialList"
          :key="j"
        >
          <div class="icon"></div>
          <div class="info">
            <div class="name">{{i.name}}</div>
            <div class="id">ID：{{i.uniqueCode}}</div>
          </div>
          <van-checkbox
            :value="i.checked"
            @click="(val) => checkboxChange(val, i)"
            checked-color="#ee0a24"
          ></van-checkbox>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import api from '../api/index-client'
import { getAuthCode } from '../lib/wechat.js'
import Cookies from 'js-cookie'
import config from '../config'
import Vue from 'vue';
import { mapGetters } from "vuex";
import md5 from 'js-md5';
import { Stepper,Tab,Tabs,Popup,Checkbox,CheckboxGroup,PasswordInput,NumberKeyboard,Button,Dialog } from 'vant';
import { Menu,MenuItem } from 'element-ui';
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Stepper);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Button);
export default {
  data () {
    return {
      list: [1,2,3,4,5,6,7,8],
      value: 0,
      activeTab: '1',
      activeIndex: '1',
      selectMaterialIndex: 0,
      checked: false,
      materialList: [], // 材料列表
      selectMaterialList: [], // 选中材料列表
      selectMaterialListMulti: [], // 选中材料列表多维数组
      materialMaxLength: 5,
      selectUnicode: [],
      data: {
        mergeTokenConfigDto: {},
        skuCountBySku: {},
        toMergeSkuVo: {},
      },
      assets: {},
      userInfo: {},
      showMaterial: false,
      isShowComposite: false,
      // do
      authVisible: false,
      show: false,
      donationErrorVisible: false,
      donationVisible: false,
      donationErrorTitle: '',
      donationErrorTip: '',
      payStep: 1,
      pwdValue: '',
      showKeyboard: true,
      accountStatus: true,
      accountStatusInfo: '实名认证：**平，请注意核对！',
      typeSelect: 'uid',
      uidOrPhopne: '',
      errorMessage: '',
      donationPay: '¥1.0',
      payType: 'weixin',
      aspectRatio: true,
      formatterStart: false,
      hasAssetsPassword: true,
      uid: '',
      userNamePhone: '',
      isAuth: true,
      buyType: '3',
      collectionInfo: {
        image: '',
        title: '繁星',
        id: '10040005709347',
        skuNo: '',
        uniqueCode: '',
        acout: '1',
        amount: '¥29.9',
        fee: '',
        buyDays: '',
        transferDays: ''
      },

    }
  },
  watch: {
    pwdValue () {
      if (this.pwdValue.length === 6) {
        this.testOldPwd()
      }
    },
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')

    if (window.location.search.split('?code=').length == 2) {
      this.getOpenid(window.location.search.split('?code=')[1].split('&state=')[0])
    } else {
      this.getData()
      this.getMyInfo()
      this.getDataCache()
    }

  },
  computed: {
    getId () {
      return this.$route.query.id || ''
    },
    planId () {
      return this.$route.query.planId || ''
    },
    getFee () {
      if (this.data.mergeTokenConfigDto.serviceFeeFlag == 1) {
        if (!Number(this.data.mergeTokenConfigDto.serviceFee)) return '0'
        if (!Number(this.value)) return '0'
        return (Number(this.data.mergeTokenConfigDto.serviceFee) * 100 * Number(this.value)) / 100
      } else {
        return '0'
      }

    },
    ...mapGetters(["userOpenId"]),
    wxPay () {
      return this.isWechat() || this.buyType == '1' || this.buyType == '3'
    },
  },
  methods: {
    // 根据code获取openId
    getOpenid (code) {
      api.get('/wechat/getOpenid?code=' + code)
        .then(result => {
          if (result.data.success) {
            if (this.userOpenId != result.data.data.openId) {
              this.setOpenId(result.data.data.openId)
            } else {
              this.startCompositing()
              // this.$store.commit('HIDE_APPLOADING')
            }
            this.$store.commit("SET_USEROPENID",result.data.data.openId)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 修改用户OpenId
    setOpenId (openId) {
      api.post('user/updateOpenid',{ openId: openId })
        .then(result => {
          if (result.data.success) {
            // this.$store.commit('HIDE_APPLOADING')
            this.startCompositing()
            this.$store.commit("SET_USEROPENID",result.data.data.openId)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    getData () {
      api
        .get(`mall/mergetoken/config/${this.getId}`)
        .then(res => {
          if (res.data.status == 200) {
            this.data = res.data.data
            res.data.data.mergeTokenConfigDto.mergePlanList.map(i => {
              if (i.planId == this.planId) {
                this.materialList = i.planList ? i.planList : []
              }
            })

            api
              .get(`mall/mergetoken/config/assets/${this.getId}?planId=${this.planId}`)
              .then(res => {
                this.$store.commit('HIDE_APPLOADING')
                if (res.data.status == 200) {
                  this.assets = res.data.data
                  localStorage.setItem('serviceFeeFlag',this.data.mergeTokenConfigDto.serviceFeeFlag)
                  // 获取最大组数
                  let arr = []
                  this.materialList.map(i => {
                    arr.push(parseInt(this.assets[i.skuNo].length / i.amount))
                  })
                  arr.sort((a,b) => a - b)
                  this.materialMaxLength = arr[0]
                  if (sessionStorage.getItem('value')) {
                    this.value = Number(sessionStorage.getItem('value'))
                  } else if (this.materialMaxLength > 0) {
                    this.value = 1
                  }
                  if (!sessionStorage.getItem('selectUnicodeLength')) {
                    this.initMaterial()
                  } else {
                    this.setMaterial()
                  }
                  this.isShowComposite = true
                  // this.list = res.data.content
                } else {
                  this.$toast({
                    message: res.data.msg,
                    icon: require('../assets/icon/toast-error.png'),
                  });
                }
              })
          } else {
            this.$toast({
              message: res.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })

    },
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.userInfo = result.data.data
            if (result.data.data.hasRealname) {
              this.$store.commit('HIDE_ISAUTH')
            } else {
              this.$store.commit('SHOW_ISAUTH')
            }
            // 是否设置过密码
            this.uid = result.data.data.uid
            this.userNamePhone = result.data.data.phone
            this.isAuth = result.data.data.hasRealname
            this.hasAssetsPassword = result.data.data.hasAssetsPassword
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 支付方式选择
    selectPay (e) {
      this.payType = e == 'zfb' ? 'alipay' : 'weixin'
      document.getElementById('zfbSelect').style.display = e == 'zfb' ? "block" : "none";
      document.getElementById('zfbCancer').style.display = e == 'zfb' ? "none" : "block";
      document.getElementById('wxSelect').style.display = e == 'zfb' ? "none" : "block";
      document.getElementById('wxCancer').style.display = e == 'zfb' ? "block" : "none";
    },
    cancel () {
      api
        .post(`mall/mergetoken/order/cancel/14`)
        .then(res => {
          if (res.data.status == 200) {
            // this.list = res.data.content
          }
        })
    },
    // 校验合成材料是否充足
    checkMeterialNum () {
      return new Promise((resolve,reject) => {
        var arr = []
        for (var i in this.assets) {
          arr = arr.concat(this.assets[i] || [])
        }
        if (!arr.length) {
          this.$toast({
            message: "藏品不足",
            icon: require('../assets/icon/toast-error.png'),
          });
          reject()
          return
        }
        if (!this.getAllUsedMaterial().length) {
          this.$toast({
            message: "未选择藏品",
            icon: require('../assets/icon/toast-error.png'),
          });
          reject()
          return
        }
        resolve()
      })
    },
    composite () {
      if (!this.isAuth) {
        this.authVisible = true
      } else {
        this.checkMeterialNum().then(() => {
          api
            .get(`mall/mergetoken/order/submit/${this.getId}/${this.value}`)
            .then(res => {
              if (res.data.status == 200) {
                this.pwdValue = ''

                if (this.hasAssetsPassword) {
                  this.payStep = 1
                  this.show = true
                } else {
                  this.donationVisible = true
                }
              } else {
                this.$toast({
                  message: res.data.msg,
                  icon: require('../assets/icon/toast-error.png'),
                });
              }
            })
        })
      }
    },
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    getAppId () {
      localStorage.setItem('donationPayInfo',JSON.stringify(
        {
          configId: this.getId,
          mergePlanId: this.planId,
          destroyAssets: this.getAllUsedMaterial(),
          mergeNum: this.value,
          from: this.payType == 'alipay' ? 'h5' : this.isWechat() ? 'wechat' : 'weixinh5', //wechat,weixinh5
          creator: {},
          payType: this.payType,
          isPaying: true
        }
      ))
      if (this.buyType == '0') {
        // 未开发购买渠道
      } else if (this.isWechat() && (this.buyType == '1' || this.buyType == '3') && this.data.mergeTokenConfigDto.serviceFeeFlag == 1) {

        window.location.href = getAuthCode(window.location.hash)
      } else if (this.isWechat() && this.buyType == '2') {
        // 微信内使用支付宝
      } else {
        this.startCompositing()
      }
    },
    startCompositing () {
      this.$store.commit('SHOW_APPLOADING')
      var param = JSON.parse(localStorage.getItem('donationPayInfo'))
      api
        .post(`mall/mergetoken/order/create`,param)
        .then(res => {
          if (res.data.status == 200) {
            this.data.mergeTokenConfigDto.serviceFeeFlag = localStorage.getItem('serviceFeeFlag')

            if (this.data.mergeTokenConfigDto.serviceFeeFlag == 1) {
              api
                .post(`mall/mergetoken/order/payOrder`,{
                  from: param.from,
                  orderId: Number(res.data.data.order.id),
                  payType: param.payType
                })
                .then(res1 => {
                  if (res1.data.status == 200) {
                    this.clearDataCache()
                    localStorage.setItem('donationOrderId',res.data.data.order.id)
                    if (Cookies.get('Device') == 'pico') {

                      this.$router.replace('/share?orderId=' + res.data.data.order.id + '&type=composite')
                    } else {
                      if (param.payType == 'alipay') {
                        localStorage.setItem('buyDetailReset',1)
                        const divForm = document.getElementsByTagName('divform')
                        if (divForm.length) {
                          document.body.removeChild(divForm[0])
                        }
                        const div = document.createElement('divform');
                        div.innerHTML = res.data.data.payOrderInfo.result.jsConfig.mweb_url; // data就是接口返回的form 表单字符串
                        document.body.appendChild(div);
                        // document.forms[0].setAttribute('target','_blank') // 新开窗口跳转
                        document.forms[0].submit();
                      } else {
                        if (this.isWechat()) {
                          window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('donationOrderId') + '&type=composite'

                          function onBridgeReady () {
                            WeixinJSBridge.invoke(
                              'getBrandWCPayRequest',{
                              "appId": res.data.data.payOrderInfo.result.jsConfig.appId,     //公众号ID，由商户传入     
                              "timeStamp": res.data.data.payOrderInfo.result.jsConfig.timestamp,         //时间戳，自1970年以来的秒数     
                              "nonceStr": res.data.data.payOrderInfo.result.jsConfig.nonceStr, //随机串     
                              "package": res.data.data.payOrderInfo.result.jsConfig.package,
                              "signType": res.data.data.payOrderInfo.result.jsConfig.signType,         //微信签名方式：     
                              "paySign": res.data.data.payOrderInfo.result.jsConfig.paySign //微信签名 
                            },
                              function (res) {
                                if (res.err_msg == "get_brand_wcpay_request:ok") {
                                  // 使用以上方式判断前端返回,微信团队郑重提示：
                                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                  //  window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('donationOrderId') + '&type=donation'
                                }
                              });
                          }
                          if (typeof WeixinJSBridge == "undefined") {
                            if (document.addEventListener) {
                              document.addEventListener('WeixinJSBridgeReady',onBridgeReady,false);
                            } else if (document.attachEvent) {
                              document.attachEvent('WeixinJSBridgeReady',onBridgeReady);
                              document.attachEvent('onWeixinJSBridgeReady',onBridgeReady);
                            }
                          } else {
                            onBridgeReady();
                          }
                        } else {
                          this.$router.replace('paySuccess?unique=' + res.data.data.order.id + '&type=composite')
                          // window.location.href = config.locationHref + 'paySuccess?unique=' + res.data.data.order.id + '&type=composite'
                          setTimeout(() => {
                            window.location.href = res.data.data.payOrderInfo.result.jsConfig.mweb_url
                          },300);
                        }
                      }
                    }
                  } else {
                    this.$store.commit('HIDE_APPLOADING')
                    localStorage.removeItem('donationPayInfo')
                    this.$toast({
                      message: res1.data.msg,
                      icon: require('../assets/icon/toast-error.png'),
                    });
                  }
                })
            } else {
              this.$router.replace('/orderDetail?orderId=' + res.data.data.order.id + '&type=composite')
            }
          } else {
            this.$store.commit('HIDE_APPLOADING')
            localStorage.removeItem('donationPayInfo')
            this.$toast({
              message: res.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    chooseMaterial (skuNo) {

      this.selectMaterial = skuNo
      // this.selectMaterialIndex = index
      this.selectMaterialList = this.assets[skuNo]
      setTimeout(() => {
        // this.showMaterial = true
        this.$router.push('/compositeSelectMaterial?from=select')
      },300)
      this.setDataCache()
    },
    getSelectList () {
      this.selectUnicode = this.selectMaterialList.filter(i => i.checked).map(m => m.uniqueCode)
    },
    checkboxChange (val,item) {
      item.checked = !item.checked
      this.$forceUpdate()
      this.getSelectList()
    },
    clearDataCache () {
      sessionStorage.removeItem('activeIndex')
      sessionStorage.removeItem('selectMaterialIndex')
      sessionStorage.removeItem('value')
      sessionStorage.removeItem('selectMaterialList')
      sessionStorage.removeItem('selectMaterialListMulti')
      sessionStorage.removeItem('selectUnicode')
    },
    setDataCache () {
      sessionStorage.setItem('activeIndex',this.activeIndex)
      sessionStorage.setItem('selectMaterialIndex',this.selectMaterialIndex)
      sessionStorage.setItem('value',this.value)
      sessionStorage.setItem('selectMaterialList',JSON.stringify(this.selectMaterialList))
      sessionStorage.setItem('selectMaterialListMulti',JSON.stringify(this.selectMaterialListMulti))

    },
    getDataCache () {
      if (sessionStorage.getItem('selectUnicode')) {
        this.selectUnicode = JSON.parse(sessionStorage.getItem('selectUnicode'))
      }

      if (sessionStorage.getItem('activeIndex')) {
        this.activeIndex = sessionStorage.getItem('activeIndex')
      }
      if (sessionStorage.getItem('selectMaterialIndex')) {
        this.selectMaterialIndex = sessionStorage.getItem('selectMaterialIndex')
      }

      if (sessionStorage.getItem('selectMaterialListMulti')) {
        this.selectMaterialListMulti = JSON.parse(sessionStorage.getItem('selectMaterialListMulti'))
      }
      this.setMaterial()
    },
    setMaterial () {
      if (sessionStorage.getItem('activeIndex')) {
        this.activeIndex = sessionStorage.getItem('activeIndex')
      }
    },
    initMaterial () {
      for (var i = 0; i < this.value; i++) {
        this.selectMaterialListMulti[i] = []
        if (this.materialList) {
          this.materialList.map((m,n) => {
            // this.selectMaterialListMulti[i].push(this.assets[m.skuNo][i])
            for (var j = 0; j < m.amount; j++) {
              this.selectMaterialListMulti[i].push(this.assets[m.skuNo][j])
            }
          })
        }
      }
      this.$forceUpdate()
    },
    getNotUsedMaterial (skuNo) {
      let arr = this.assets[skuNo], // 所有材料
        arrUsed = [], // 已使用材料
        result = []
      this.selectMaterialListMulti.map(i => {
        i.map((m,n) => {
          if (m.skuNo == skuNo) {
            arrUsed.push(m)
          }
          return m
        })
        return i
      })
      arr.map(x => {
        if (arrUsed.every(y => y.uniqueCode != x.uniqueCode)) {
          result.push(x)
        }
      })
      return result
    },
    getAllUsedMaterial (skuNo) {
      let arr = this.assets[skuNo], // 所有材料
        arrUsed = [], // 已使用材料
        result = []
      this.selectMaterialListMulti.map(i => {
        i.map((m,n) => {
          result.push(m.uniqueCode)
          return m
        })
        return i
      })
      return result
    },
    valuePlus () {
      this.selectMaterialListMulti[this.value] = []
      if (this.materialList) {
        this.materialList.map((m,n) => {
          let arr = this.getNotUsedMaterial(m.skuNo)
          for (let j = 0; j < m.amount; j++) {
            this.selectMaterialListMulti[this.value].push(arr[j])
          }
        })
      }
      setTimeout(() => {
        this.setDataCache()
      },1000)
    },
    valueMinus () {
      this.selectMaterialListMulti = this.selectMaterialListMulti.slice(0,this.value - 1)
    },
    valueChange (val) {
      this.activeIndex = `${val}`
    },
    // 选择组数
    handleSelect (val) {
      this.activeIndex = `${val}`
    },
    // 校验资产密码
    testOldPwd () {
      api
        .post('user/verifyAssetsPassword',{ assetsPassword: md5(this.pwdValue) })
        .then(result => {
          if (result.data.success) {
            if (this.data.mergeTokenConfigDto.serviceFeeFlag == 1) {
              this.getPayChannel()
            } else {
              // 处理转赠手续费为0时的逻辑
              this.$store.commit('SHOW_APPLOADING')
              api
                .post(`mall/mergetoken/order/create`,{
                  configId: this.getId,
                  mergePlanId: this.planId,
                  destroyAssets: this.getAllUsedMaterial(),
                  mergeNum: this.value,
                  from: this.payType == 'alipay' ? 'h5' : this.isWechat() ? 'wechat' : 'weixinh5', //wechat,weixinh5
                  creator: {},
                  payType: this.payType,
                  isPaying: true
                })
                .then(res => {
                  if (res.data.status == 200) {
                    localStorage.setItem('donationOrderId',res.data.data.order.id)
                    this.$router.replace('/paySuccess?unique=' + res.data.data.order.id + '&type=composite')
                  } else {
                    this.$store.commit('HIDE_APPLOADING')
                    localStorage.removeItem('donationPayInfo')
                    this.$toast({
                      message: res.data.msg,
                      icon: require('../assets/icon/toast-error.png'),
                    });
                  }
                })
            }
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // 获取后台配置支付状态
    getPayChannel () {
      api
        .get('common/getPayChannel')
        .then(result => {
          if (result.data.success) {
            this.buyType = result.data.data.pay_merge_token_order
            this.payType = this.buyType == '2' ? 'alipay' : 'weixin'
            this.payStep = 2
          } else {
            this.$store.commit('HIDE_APPLOADING')
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 离开收银台
    closePopup () {
      Dialog.confirm({
        message: '确定要离开收银台吗？',
      })
        .then(() => {
          // on confirm
          this.show = false
          this.$router.push('/compositeOrder')
        })
        .catch(() => {
          // on cancel
          // this.show = false
        });
    }
  }
}
</script>

<style lang="less" scoped>
.composite {
  margin-top: 3.875rem;
  padding-bottom: 4rem;
  .goods {
    margin: 1.3125rem 0.875rem 0;
    display: flex;
    border-radius: 0.5rem;
    overflow: hidden;
    .icon {
      width: 6.25rem;
      height: 6.25rem;
      background: #ffffff;
      flex-grow: 0;
      &-container {
        width: 6.25rem;
        height: 6.25rem;
        display: block;
        position: relative;
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
    .infos {
      flex-grow: 1;
      background: #2c2c2c;
      padding-left: 1.8125rem;
      .name {
        margin: 1.1875rem 0 0.75rem;
        font-size: 1.125rem;
        font-weight: bold;
        color: #ffffff;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .count {
        display: flex;
        // margin-bottom: 0.9375rem;
        font-size: 0.75rem;

        .count-l {
          // width: 3.125rem;
          padding: 2px 5px;
          height: 1.25rem;
          line-height: 1.25rem;
          background: #f3cdb6;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #010804;
          p {
            margin-left: 2px;
          }
        }
        .count-r {
          // width: 3.75rem;
          padding: 2px 5px;
          height: 1.25rem;
          line-height: 1.25rem;
          background: #484848;
          border-radius: 0px 4px 4px 0px;
          color: #f3cdb6;
          text-align: center;
        }
      }
    }
  }
  .num {
    height: 3.5625rem;
    background: #2c2c2c;
    border-radius: 0.5rem;
    padding: 0 0.6875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0.875rem 0;
    p {
      color: #f0f0f0;
      font-size: 0.875rem;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
    }
    .van-stepper {
      color: #f0f0f0;
      /deep/ .van-stepper__minus,
      /deep/ .van-stepper__plus {
        background: transparent !important;
        color: #f0f0f0;
      }
      /deep/ input {
        background: #545454 !important;
        color: #f0f0f0;
      }
    }
  }
  .material-title {
    // margin: 0.875rem 0 12px 11px;
    margin: 0.875rem 0.875rem 12px;
    padding-left: 0.6875rem;
    font-size: 0.875rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #f0f0f0;
  }
  .material {
    margin: 0 0.875rem 0.75rem;
    background: #2c2c2c;
    border-radius: 0.5rem;
    padding: 0.75rem;
    .nav {
      width: 100%;
      overflow-x: auto;
      background: #2c2c2c;
      overflow-y: hidden;
      .el-menu {
        margin-left: 0.5rem;
        /deep/ .el-menu-item:not(.is-disabled):focus,
        /deep/.el-menu-item:not(.is-disabled):hover,
        /deep/.el-submenu__title:hover {
          background: #2c2c2c !important;
        }
      }
    }
    .groups {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      // align-items: center;
      flex-direction: row;
    }
    .group {
      margin: 0.625rem 0 1rem 0.4rem;
      width: 6.3125rem;
      .icon {
        width: 6.3125rem;
        height: 6.3125rem;
        background: #ffffff;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        &-container {
          width: 6.3125rem;
          height: 6.3125rem;
          display: block;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
        .icon-modal {
          width: 6.3125rem;
          height: 6.3125rem;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.6);
          &-container {
            width: 6.3125rem;
            height: 6.3125rem;
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              font-size: 0.875rem;
              font-weight: normal;
              color: #ffffff;
            }
          }
        }
      }
      // .icon::hover {
      //   .icon-modal {
      //     display: block;
      //   }
      // }
      .name {
        margin: 10px 0 4px;
        color: #ffffff;
        font-size: 0.75rem;
      }
      .id {
        font-size: 0.75rem;
        color: #999999;
      }
    }
  }
  .fee {
    height: 3.5625rem;
    background: #2c2c2c;
    border-radius: 0.5rem;
    padding: 0 0.6875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0.875rem 0;
    p {
      color: #f0f0f0;
      font-size: 0.875rem;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      &:last-child {
        color: #b3b3b3;
        font-size: 0.75rem;
      }
    }
  }
  .tips {
    margin: 1.3125rem 0.6875rem;
    padding-left: 0.4375rem;
    font-size: 0.75rem;
    font-weight: normal;
    color: #666666;
  }
  .btns {
    // width: 375px;
    height: 3.3125rem;
    background: #080808;
    padding-top: 0.4375rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    .completed-pay {
      width: 19.375rem;
      height: 2.875rem;
      margin: 0 auto;
      line-height: 2.875rem;
      backdrop-filter: blur(1.1875rem);
      text-align: center;
      font-size: 1rem;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .van-popup {
    .donation-popup-title {
      margin: 1.625rem 0 1.25rem 0;
      text-align: center;
      font-size: 1.125rem;
      font-family: lantingheiweight;
      font-weight: 700;
      color: #f0f0f0;
    }
    .donation-popup-donationPay {
      text-align: center;
      font-size: 2.1875rem;
      font-family: SFUIDisplay-Bold, SFUIDisplay;
      font-weight: bold;
      color: #ffffff;
      line-height: 2.625rem;
      margin-bottom: 2.4375rem;
    }
    .donation-popup-tip {
      margin: 0 0 0.875rem 1rem;
      font-size: 0.875rem;
      font-family: lantingheiweight;
      font-weight: 600;
      color: #666666;
      line-height: 19px;
    }
    .van-password-input__security li {
      background: #565656;
      border-radius: 0.25rem;
    }
    .van-number-keyboard {
      background: rgba(21, 21, 21, 0.85);
    }
    /deep/.van-number-keyboard .van-key {
      background-color: #646465;
      box-shadow: 0px 1px 0px 0px #000000;
      font-family: Helvetica;
      color: #ffffff;
    }
    .order-detail-wx,
    .order-detail-zfb {
      width: 90%;
      height: 3.25rem;
      margin-left: 5%;
      background: #313131;
      border-radius: 0.25rem;
      .zfb-icon {
        width: 1.625rem;
        height: auto;
        margin: 0 2rem 0 1.3125rem;
      }
      .wx-icon {
        width: 2rem;
        height: auto;
        margin: 0 1.75rem 0 1.1875rem;
      }
      .wx-tip,
      .zfb-tip {
        font-size: 0.875rem;
        font-family: lantingheiweight;
        font-weight: 700;
        color: #e4e4e4;
        line-height: 19px;
      }
      .buy-zfb-select,
      .buy-zfb-cancer,
      .buy-wx-select,
      .buy-wx-cancer {
        width: 1.25rem;
        height: auto;
        margin-right: 0.625rem;
      }
      .buy-zfb-cancer,
      .buy-wx-select {
        display: block;
      }
      .buy-wx-cancer,
      .buy-zfb-select {
        display: none;
      }
    }
    .order-detail-wx {
      margin-bottom: 0.9375rem;
    }
    .order-detail-zfb {
      margin-bottom: 1.5625rem;
    }
  }
  .materailPop {
    overflow: auto;
    /deep/ .van-icon {
      display: none;
    }
    .materials1 {
      // padding-top: 77px;
      margin: 4.8125rem 0.75rem 0;
      background: #2c2c2c;

      .material1 {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 1rem 0 1.25rem 0;
        border-bottom: 1px solid #3f3f3f;
        margin: 0 1.3125rem 0 0.8125rem;
        &:last-child {
          border-bottom: 0;
        }
        .icon {
          width: 5rem;
          height: 5rem;
          flex-grow: 0;
          background: #d8d8d8;
          border-radius: 8px;
        }
        .info {
          flex-grow: 1;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          position: relative;
          margin-left: 0.9375rem;
          height: 65px;
          padding-top: 0.9375rem;
          .name {
            font-size: 1.125rem;
            font-weight: normal;
            color: #ffffff;
            margin: 0px 0 0.5625ren;
          }
          .id {
            font-size: 0.75rem;
            font-weight: normal;
            color: #999999;
          }
          .van-checkbox {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .el-menu {
    display: block;
    background: #2c2c2c !important;
    /deep/ .el-menu-item {
      width: 45px;
      margin-right: 10px;
    }
  }
  .close-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    img {
      width: 1rem;
      height: 1rem;
    }
  }
  .pay-btn {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0 auto;
  }
  .material-item-container {
    margin: 0 0.875rem 1rem 0.875rem;
    height: 84px;
    background: #262626;
    border-radius: 4px;
    .material-item-container-left-image {
      width: 62px;
      height: 62px;
      margin: 0 10px 0 14px;
    }
    .material-item-container-left-name {
      font-size: 14px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #fefefe;
      margin-bottom: 15px;
    }
    .material-item-container-left-amount {
      font-size: 13px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #cccccc;
    }
    .material-item-container-right-tip {
      font-size: 13px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #818181;
      margin-right: 4px;
    }
    .material-item-container-right-icon {
      width: 9px;
      height: auto;
      margin-right: 10px;
    }
  }
}
</style>